///
/// Editorial by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Footer */

	#footer {
		.copyright {
			color: _palette(fg-light);
			font-size: 0.9em;

			a {
				color: inherit;
			}
		}
	}